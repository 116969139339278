<template>
    <div class="container-fluid">
        <div class="row ps-3 m-0 p-0">
            <div class="col-md-auto">
                <button class="btn btn-success rounded-pill" @click="showAdd()"><i class="fa fa-add"></i>Tambah DO</button>
            </div>
            <div class="col-md-11">
                <Toolbar
            :click-edit="showEdit"
            buttonName="Detail Do"
            :click-refresh="showRefresh"
            :set-filter="loadDataSearch"
            :status-print="false"
            :data   = "recs"
            :fields = "json_fields"
            type    = "xls"
            name    = "Approve.xls"
        />
            </div>
        </div>
        <div class="container-fluid p-0">
            <div id="wrapper">
                <div id="content">
                    <div class="w3-hide-small w3-hide-medium" id="boundary">
                        <table id="header" cellpadding="0" cellspacing="5" border="0" class="table table-hover table-strip">
                            <thead>
                                <tr class="">
                                    <th width="50px">No. </th>
                                    <th v-for="fl in filterByShow" :width="fl.width" :key="fl.name" class="h9 text-center">{{fl.name}}</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="scrlt-y" v-bind:style="'height:'+  (screenHeight-215) +'px;'">
                        <table class="table table-hover table-strip table-bordered">
                            <tbody>
                            <tr v-for="(dt, index) in recs" :key="dt.td_so" class="w3-hover-pale-blue w3-small cursor" :class="index == selectedRow ?'w3-pale-blue':''">
                                <td class="w3-hide-large" v-on:click="setKlikRow(index, dt)">
                                    <div class="w3-col s4 m2 w3-theme-l5">
                                        <i :class="index == selectedRow ?'fa fa-caret-right':''"></i>Nomer SO
                                    </div>
                                    <div class="w3-col s8 m10 w3-theme-l4">
                                        : {{dt.td_so}}
                                    </div>
                                </td>
                                <td width="50px" class="text-center">
                                    <i :class="index == selectedRow ?'fa fa-caret-right':''"></i>&nbsp;{{dt.idx}}
                                    <span class="w3-hide-large" v-html="setStatus(dt.ta_approved)"></span>
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','td_so')" class="w3-hide-small w3-hide-medium ">
                                    {{dt.td_so}}
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','tc_name')" class="w3-hide-small w3-hide-medium ">
                                    {{dt.tc_name}}
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','td_date')" class="w3-hide-small w3-hide-medium ">
                                    {{dt.td_date}}
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:' + findObjFind('width','ta_approved')" class="w3-hide-small w3-hide-medium">
                                    <span v-html="setStatus(dt.ta_approved)"></span>
                                </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </div>


        <!-- pagging -->
        <nav class="Page navigation h9 bg-light bg-gradient shadow">
            <div class="d-flex justify-content-center">
                <ul class="pagination text-center">
                    <li class="page-item page-item active"><a :class="pages.halaman == 1 ?'dsb':''" class="page-link" @click="first()" href="#">First</a></li>
                    <li class="page-item page-item active"><a :class="pages.halaman == 1 ?'dsb':''" class="page-link" @click="prev()" href="#">«</a></li>
                    <li class="page-item page-item active"><a class="page-link px-0 px-md-2" href="#">Page {{pages.halaman}} sd {{pages.ttlhalaman}}</a></li>
                    <li class="page-item page-item active"><a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="page-link" @click="next()" href="#">»</a></li>
                    <li class="page-item page-item active"><a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="page-link" @click="last()" href="#">Last</a></li>
                    <li class="page-item page-item active"><a class="page-link  page-item active" href="#">Rec {{pages.ttlrec}}</a></li>
                    </ul>
            </div>
        </nav>
        </div>
    </div>
        <!-- form add so -->

        <div id="modal-add" class="modal row mt-4 top-50 start-50 translate-middle ms-md-0 ms-1" style="max-width: 600px;max-height:650px;">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="w3-animate-zoom modal-content col-md-3">
                    <div class="modal-header pb-0">
                        <button onclick="document.getElementById('modal-add').style.display='none'" class="btn w3-display-topright btn-lg">&times;</button>
                        <p class="h9 fw-bold"><i class="mdi mdi-file-document-multiple-outline"></i>&nbsp; Tambah Do || {{ recust}}</p>
                    </div>
                    <div class="modal-body">
                        <p class="fw-bold h9">
                            Masukan Nomer SO :
                        </p>
                        <form class="container d-flex mb-4" id="frmDo" v-on:submit.prevent="cekItem" autocomplete="off">
                            <input type="text" class="form-control" id="td_so" v-model="red.td_so" placeholder="isikan nomer so">
                            <input type="submit" class="btn btn-warning ms-3" value="cek">
                        </form>
                        <div class="row">
                            <div class="col-auto ">
                                <p class="">Nama Customer :</p>
                            </div>
                            <div class="col">
                                <p class="fw-bold">
                                    {{ recust}}
                                </p>
                            </div>
                        </div>
                        <p class="fw-bold h9">
                            Daftar barang
                        </p>
                        <table class="table table-hover table-responsive" id="doDtl" style="cursor:pointer">
                            <thead>
                                <tr class="table-head">
                                    <th class="">No</th>
                                    <th class="">Nama Barang</th>
                                    <th class="">Qty</th>
                                    <th class="">Gudang</th>
                                </tr>
                            </thead>
                        </table>

                    </div>
                    <div class="modal-footer"  style="margin-top: 15px;">
                        <h6 class="w3-right">
                        <button @click="addDo" class="btn btn-success rounded" type="submit"><i class="fa fa-save"></i> Save</button>
                        <button type="button" class="btn btn-danger ms-2 rounded" onclick="document.getElementById('modal-add').style.display='none'"><i class="fa fa-close"></i> Close</button>
                        </h6>
                    </div>
                    </div>
            </div>
        </div>

        <div id="modal-dtl" class="modal row mt-4 top-50 start-50 translate-middle ms-md-0 ms-1" style="max-width: 600px;max-height:650px;">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="w3-animate-zoom modal-content col-md-3">
                    <div class="modal-header pb-0">
                        <button onclick="document.getElementById('modal-dtl').style.display='none'" class="btn w3-display-topright btn-lg">&times;</button>
                        <p class="h9 fw-bold"><i class="mdi mdi-file-document-multiple-outline"></i>&nbsp; Detail Do || {{ recust}}</p>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-auto ">
                                <p class="">Nama Customer :</p>
                            </div>
                            <div class="col">
                                <p class="fw-bold">
                                    {{ recust}}
                                </p>
                            </div>
                        </div>
                        <p class="fw-bold h9">
                            Daftar barang
                        </p>
                        <table class="table table-hover table-responsive mb-3" id="doDtls" style="cursor:pointer">
                            <thead>
                                <tr class="table-head">
                                    <th class="">No</th>
                                    <th class="">Nama Barang</th>
                                    <th class="">Qty</th>
                                    <th class="">Gudang</th>
                                </tr>
                            </thead>
                        </table>
                        <form class="container d-flex mb-4" id="frmDo" v-on:submit.prevent="Editc" autocomplete="off">
                            <input type="text" class="form-control" id="td_so" v-model="red.td_so" placeholder="isikan Catatan">
                            <input type="submit" class="btn btn-warning ms-3" value="Catatan">
                        </form>
                        <table class="w3-table-all">
                            <thead>
                                <tr class="">
                                    <th class="w3-border-right">Gudang</th>
                                    <th class="w3-border-right w3-center">Catatan</th>
                                    <th class="w3-border-right w3-center">Aksi</th>
                                </tr>
                            </thead>
                            <tbody class="w3-small">
                                <tr></tr>
                            </tbody>
                        </table>

                    </div>
                    <div class="modal-footer"  style="margin-top: 15px;">
                        <h6 class="w3-right">
                        <button type="button" class="btn btn-danger ms-2 rounded" onclick="document.getElementById('modal-dtl').style.display='none'"><i class="fa fa-close"></i> Close</button>
                        </h6>
                    </div>
                    </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import Config from '@/conf/Config.js';
import axios from "axios";
import "vue-search-select/dist/VueSearchSelect.css";
import Toolbar from "@/components/Toolbar2.vue";

import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-buttons-bs5/js/buttons.bootstrap5"
import "datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css"
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";


export default {

    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'Approved',
    components: {
        Toolbar
    },
    data() {
        return {
            username : localStorage.username,
            userz : [],
            screenHeight : 0,
            selectedRow : -1,
            DatePickerFormat: 'YYYY-MM-DD',
            sys : {sys : ''},
            rec: {},
            recust:'',
            red: {},
            recs: [],
            recz: {},
            reco: {},
            reci: [],
            pages : {halaman : 1, batas : 0, ttlhalaman : 0, ttlrec:0, limit:Config.perpage, search:''},
            filters : {
            	operator : 'AND',
                fields :[
                        {fieldFind : '', key : 'td_so', type: 'text', name : 'Nomer SO', filter : true, width : '250px', grid : true},
                        {fieldFind : '', key : 'tc_name', type: 'text', name : 'Nama Customer', filter : true, width : '250px', grid : true},
                        {fieldFind : '', key : 'td_date', type: 'text', name : 'Tanggal', filter : true, width : '120px', grid : true},
                        {fieldFind : '', key : 'ta_approved', type: 'text', name : 'Status', filter : true, width : '35px', grid : true, valueFind: [{key:'1',label:'Approved'},{key:'0',label:'Non'}]},

                ],
                find : []
            },
            /**Untuk transfer excel*/
            json_fields : {
                'SO' : 'td_so',
                'NAMA CUSTOMER' : 'ta_idcust',
                'TANGGAL PENGAJUAN' : 'ta_date',
                'OUT STANDING SO' : 'ta_outstanding',
                'NOMINAL' : 'ta_amt',
                'KREDIT LIMIT' : 'ta_limit',
                'TEMPO' : 'ta_top',
                'PEMBUAT SO' : 'changed_by',
                'STATUS' : 'ta_approved',
                'ALASAN PENGAJUAN' : 'ta_reason'
            },
        }
    },
    methods : {
        findObjFind(key, val){
            return this.findObj(this.filters.fields,'key',val)[key];
        },
        findField(val){
            return this.findObj(this.fieldRules, 'field',val);
        },
        findObj(obj, key, value) {
            let ketemu = 0;
            for (var pkey in obj) {
                if (obj[pkey][key] == value) {
                    break;
                } else {
                    ketemu++;
                }
            }
            if(obj[ketemu]!=undefined){
                return obj[ketemu];
            }else
                return null;
        },
        first(){
            let self = this;
            self.pages.halaman = 1;
            self.loadData();
        },
        prev(){
            let self = this;
            self.pages.halaman = self.pages.halaman - 1;
            self.loadData();
        },
        next(){
            let self = this;
            self.pages.halaman = self.pages.halaman + 1;
            self.loadData();
        },
        last(){
            let self = this;
            self.pages.halaman = self.pages.ttlhalaman;
            self.loadData();
        },
        setKlikRow(index, dt){
            let self = this;
            self.selectedRow = index;
            self.rec = Object.assign({}, dt);
        },
        loadData(){
            this.screenHeight = window.innerHeight;
            let self = this;
            self.selectedRow = -1;
            self.dept;
            self.rec = {};
            let params = {
                pfunction:'show',
                limit: self.pages.limit,
                page: self.pages.halaman,
                search: self.pages.search,
                dept : self.pages.dept
            }
            axios.post("marketing/ApiDo.php",params).then(function (response) {
                let idx = (self.pages.limit*self.pages.halaman)-self.pages.limit+1;
                self.pages.ttlrec = parseFloat(response.data.ttlrec);
                self.pages.ttlhalaman = Math.ceil(parseFloat(response.data.ttlrec)/self.pages.limit);
                self.pages.dept = self.pages.dept;
                response.data.rec.map(function(val){
                    val['idx'] = idx;
                    idx++;
                });
                self.recs = response.data.rec;
            });
        },
        showRefresh(){
            this.pages.search = "";
            this.loadData();
        },
        loadDataSearch(filter){
            this.pages.search = filter;
            this.loadData();
        },
        showAdd(){
            let self = this;
            document.getElementById('modal-add').style.display='block';
        },
        cekItem(){
            let self = this;
            self.td_so = self.red.td_so
            axios.post("marketing/ApiDo.php",{
                pfunction : 'showitm',
                td_so : self.td_so
            }).then(function (response) {
                self.reco = response.data.reci;
                if (self.reco.length > 0) {
                    self.recust = self.reco[0].custname;
                } else {
                    self.recust = 'Unknown';
                }
                $('#doDtl').DataTable({
                    destroy: true,
                    responsive: true,
                    scrollX: true,
                    deferRender: true,
                    autoWidth: false,
                    data : self.reco,
                    columns: [
                        {"data": "LineNo"},
                        {"data": "Description"},
                        {"data": "Qty"},
                        {"data": "gudang"},
                    ],
                    columnDefs: [
                    ],
                    dom:
                    "<'row'<'col-md-12 h9 mb-3'tr>>"
                });
            });
                document.getElementById('modal-form').style.display='block';
                setTimeout(function(){
                    document.getElementById('td_so').focus();
                },500);
        },
        addDo(){
            let self = this;
           axios.post("marketing/ApiDo.php",{
            pfunction : 'adddo',
            td_so : self.red.td_so,
            tc_id : self.reco[0].custid,

           }).then(function (response){
            if(response.data.success == true){
                    self.$toast.success(response.data.msg);
                    document.getElementById('modal-add').style.display='none';
                }else{
                    self.$toast.error(response.data.msg);
                }
            });
            event.preventDefault();
            return false;

        },
        showEdit(){
            let self = this;
            if (self.selectedRow < 0) {
                this.$toast.error('Pilih Data');
                return false;
            }else{
            axios.post("marketing/ApiDo.php",{
                pfunction : 'showdo',
                so : self.rec.td_so

            }).then(function (response) {
                self.reco = response.data.recdtl;
                if (self.reco.length > 0) {
                    self.recust = self.reco[0].custname;
                } else {
                    self.recust = 'Unknown';
                }
                $('#doDtls').DataTable({
                    destroy: true,
                    responsive: true,
                    scrollX: true,
                    deferRender: true,
                    autoWidth: false,
                    data : self.reco,
                    columns: [
                        {"data": "LineNo"},
                        {"data": "Description"},
                        {"data": "Qty"},
                        {"data": "gudang"},
                    ],
                    columnDefs: [
                    ],
                    dom:
                    "<'row'<'col-md-12 h9 mb-3'tr>>"
                });
            });
                document.getElementById('modal-dtl').style.display='block';
                setTimeout(function(){
                    document.getElementById('td_so').focus();
                },500);
            }
        },
        saveData(event){
            var self = this;
            if (self.username === 'jw') {
                self.rec.pfunction = "editjw";
            }else{
                self.rec.pfunction = "edit";
            }
            axios.post("marketing/ApiApproved.php",self.rec).then(function (response) {
                if(response.data.success == true){
                    self.$toast.success(response.data.msg);
                    if (self.username) {
                        self.recs[self.selectedRow] = self.rec;
                    }else{
                        self.recs[self.selectedRow] = self.rec;

                    }
                    document.getElementById('modal-form').style.display='none';
                    self.selectedRow = -1;
                }else{
                    self.$toast.error(response.data.msg);
                }
            });
            event.preventDefault();
            return false;
        },
        setStatus(dt) {
            if (dt === "1") {
                return "<div class='btn btn-success btn-sm' ><span class='fa fa-check'></span></div>";
            } else {
                return "<div class='btn btn-danger btn-sm'><span class='fa fa-close'></span><div>";
            }
        },
        setInit() {
            let self = this;
            axios.post("master/ApiApproved.php",{
                pfunction : 'setapproved'
            }).then(function (response) {
                self.customers = response.data.rec;
            });
        }
    },
    filters: {
        toRp(value) {
            return `${Intl.NumberFormat("id-ID",{ style: 'currency', currency: 'IDR' }).format(value)}`;
        },
        pNumber(num) {
            var x = num.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})(\d{0,4})/);
            return !x[2] ? x[1] : '(' + x[1] + ')-' + x[2] + (x[3] ? '' + x[3] : '');
        }
    },
    watch: {
        // 'moveCenter': function(){
        //     console.log("OK");
        // }
    },
    computed :{
        filterByShow(){
            return  this.filters.fields.filter(event => {
                return event.grid == true;
            });
        }
    },
    mounted(){
        this.screenHeight = window.innerHeight;
        // this.setInit();
        this.loadData();
    }
};
</script>